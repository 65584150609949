@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 2.5rem;
}

svg {
  height: 2rem;
}

.button {
  padding: 0.8em 2em;
}

.scaleUp {
  transition: 0.3s;
}
.scaleUp:hover {
  scale: 1.1;
}

.header-cont {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
}
.header-cont .header {
  width: min(1400px, 100vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.header-cont .header a {
  color: inherit;
}
.header-cont div {
  display: flex;
}
.header-cont div svg {
  position: absolute;
  right: 1.2rem;
  top: 1.4rem;
}
.header-cont .cart-item {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  font-size: 0.7rem;
  font-weight: 700;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.2rem;
  top: 0.2rem;
}

.card-container {
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.item-card {
  padding: 0.8rem;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  justify-content: flex-start;
  background-color: #c9d2d9;
}
.item-card img {
  width: 100%;
  transition: 0.3s;
}
.item-card p {
  max-height: 2.8rem;
  overflow: hidden;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.item-card p svg {
  height: 1rem;
}
.item-card .meta {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.item-card .price {
  font-weight: 700;
  color: #ff2900;
}
.item-card .averageRating {
  font-size: 0.8rem;
}
.item-card .cart-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
}
.item-card .cart-toggle button {
  width: 1rem;
  font-weight: 700;
}
.item-card .add-to-cart {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}